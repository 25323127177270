















































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab3DiscussionB',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        discussion: null,
        language: 'en',
      },
      rules: [(v: string) => v?.length <= 3000 || 'Max 3000 characters'],
      questions: [
        {
          en: 'Explain your results keeping your experiment in mind. Relate your results to the theory relevant to the experiment.',
          fr: "Expliquez vos résultats en gardant à l'esprit votre expérience. Reliez vos résultats à la théorie pertinente pour l'expérience.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
